* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  font-family: "Arial";
  color: white;
  background-color: black;
  overflow: hidden;
}
body {
  position: relative;
}

canvas {
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  z-index: 1;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#bg {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}

#info {
  position: absolute;
  text-align: center;
  display: block;
  top: 85%;
  width: 100%;
  max-height: 100%;
  z-index: 100;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
